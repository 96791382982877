* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #fbfbfb !important;
  font-family: "Futura Md BT" !important;
}

h2 {
  font-family: "RecifeDisplay";
}

.login-box-inner-wrap h2 {
  color: #000;
  font-family: "RecifeDisplay";
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 32px;
  letter-spacing: -0.6px;
}
.form-set {
  display: flex;
  height: 164px;
  flex-direction: column;
  justify-content: center;
}
.login-cmn-box {
  border: 1px solid #b9b5ab;
  background: #f0efec;
  margin: auto;
  max-width: 750px;
}
.login-box-inner-wrap {
  max-width: 550px;
  margin: auto;
  padding: 45px 0;
  min-height: 450px;
}
.login-box-inner-wrap form {
  margin-top: 35px;
}
.login-box-inner-wrap label {
  color: #000;

  font-size: 15px;
  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.login-box-inner-wrap input {
  background: #fff;
  border-radius: 0;
  height: 42px;
}
.login-box-inner-wrap input::placeholder {
  color: #6a6d60;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
}
.submit {
  margin-top: 40px !important;
  border: 1px solid #071523;
  background: #071523;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  color: #fff;
  text-align: center;

  font-size: 15px;
  font-weight: 400;
  width: 150px;
  height: 42px;
  text-decoration: none;
  margin: auto;
}
.forgot {
  color: #000;
  text-align: center;

  font-size: 15px;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
  margin-top: 20px;
}
.login-box-inner-wrap input:focus {
  box-shadow: none;
}
.logo {
  margin-left: 90px;
  margin-top: 20px;
}
.box-center.row {
  height: calc(100vh - 240px);
  align-items: center;
}

aside {
  border-right: 1px solid #000;
  background: #fff;
  width: 270px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1;
  overflow-y: scroll;
}
aside::-webkit-scrollbar {
  display: none;
}
.logo-side img {
  width: 180px;
  margin: 30px 23px;
}

.side-menu a {
  color: #5f6368;
  display: flex !important;
  align-items: center;
  font-size: 16px;
  padding: 10px 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  display: block;
}
.side-menu a svg {
  margin-right: 13px;
  width: 17px;
}
.side-menu a p {
  margin: 0;
}
/* .sidebar {
    padding: 0 30px;
} */

.side-menu .side-btm-space {
  margin-bottom: 20px;
}
.side-menu .side-btm-last {
  margin-top: 40px;
}

.main-wrap {
  display: flex;

  height: 100vh;
}
.height-set {
  height: 100%;
}
.right-side-cmn {
  width: calc(100% - 270px);
  position: relative;
  margin-left: auto;

  height: 100vh;
}
.right-top {
  background: #b9b5ab;
}

.heading-top h2 {
  color: #000;
  font-family: "RecifeDisplay";
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.6px;
  margin: 0;
}
.heading-top {
  padding: 25px 20px;
}
.right-top hr {
  color: rgba(255, 255, 255, 0.2);

  margin: 1px !important;
  opacity: 1;
}

.cmn-top-fields {
  padding: 20px 7px;
}
.cmn-top-fields input {
  background: #fff;
  width: 100%;
  border-radius: 0;
  border: 0.5px solid #d8d7ce !important;
  color: #6a6d60;
  font-size: 14px;
  height: 40px;
  padding-left: 20px;
}
.cmn-top-fields input::placeholder {
  color: #6a6d60;

  font-size: 14px;

  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.cmn-top-fields input:focus {
  box-shadow: none;
  border: 0.5px solid #d8d7ce !important;
}
.table-cmn tr td p {
  margin: 0;
  color: #3a7563;
}
.cmn-top-fields select:focus {
  box-shadow: none;
  border: 0.5px solid #d8d7ce !important;
}
.cmn-top-fields select {
  color: #6a6d60;

  height: 40px;
  max-width: 270px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
}
.cmn-btn a {
  border: 1px solid #071523;
  background: #071523;
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
}
.product-tab ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 170px);
  margin-top: 10px;
}
.product-tab ul li {
  list-style: none;
  padding: 0;
}
.product-tab {
  padding: 12px 10px;
}
.product-tab ul li a {
  color: rgba(7, 21, 35, 0.5);

  font-size: 15px;
  text-transform: capitalize;
  position: relative;
  padding: 15px 0px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
  margin-right: 35px;
}
.product-tab .active-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: #000;
  width: 100%;
  height: 2px;
}
.product-tab .active-tab {
  color: #000 !important;
}
.product-tab ul li a span {
  margin-left: 10px;
}
.show-results {
  color: #000;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.select-result span {
  color: #000;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
  padding-right: 20px;
}
.select-result select {
  padding: 4px 10px;
  width: 55px;
  border: 1px solid #c7c4bc;
  background: #fff;
  border-radius: 0;
  color: #000;

  font-size: 13px;
  background-repeat: no-repeat;
  background-position: center right 3px;
  font-weight: 400;
  background-image: url("../Images/Expand_down.svg");
  letter-spacing: -0.3px;
}
.select-result select:focus {
  box-shadow: none;
  border: 1px solid #c7c4bc;
}
.results-sec {
  padding: 10px 10px;
}
.table-cmn tr th {
  padding: 12px 12px;
  background: #f0efec;
  color: #071523;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  text-transform: capitalize;
}
.table-cmn tr td {
  padding: 8px 12px;
  color: #5f6368;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.table-cmn tr td a {
  color: #071523;

  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.table-cmn tr th:first-child {
  padding-left: 23px;
}
.table-cmn tr td:first-child {
  padding-left: 23px;
}
.next-btn-fix {
  background: #fff;
  text-align: end;
  position: fixed;
  width: calc(100% - 270px);
  padding: 20px 40px;
  bottom: 0;
  border-top: 1px solid #e9edf7;
}
.next-btn-fix a {
  padding: 7px 35px;
  border: 1px solid #071523;
  background: #071523;
  color: #fff !important;

  font-size: 15px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
}

.back-white {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.inner-tab-cmn a {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #40413a;
  text-align: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 10px 25px;
  margin-left: 15px;
}
.inner-tab-cmn .active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}

.transactions-box {
  margin-top: 16px;
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 20px 30px;
}
.transactions-box h4 {
  color: #3a7563;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  letter-spacing: -0.3px;
}
.transactions-box h2 {
  color: #3a7563;

  font-size: 24px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
  margin-top: 12px;
}
.transaction-graph img {
  width: 100%;
  height: 224px;
}
.transaction-graph {
  margin-top: 15px;
}
.graph-months {
  display: flex;
  justify-content: space-between;
  max-width: 340px;
  margin: auto;
}
.graph-months h5 {
  color: rgba(65, 65, 65, 0.4);
  font-family: Poppins;
  font-size: 12.171px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.transaction-box-wrap {
  padding: 0 10px;
}

.dashbox-inner-wrap {
  background: #fbfbfb;
  padding: 25px 0;
}
.dashbox-inner-wrap h2 {
  color: #3a7563;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.dashbox-inner-wrap select.form-select {
  border: 1px solid #c1dece;
  background: #fff;
  color: #000;

  height: 40px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
  background-image: url("../Images/Expand_down.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}
.dashbox-inner-wrap select.form-select:focus {
  box-shadow: none;
}
.dash-inner-boxes {
  border: 0.25px solid #6a6d6036;
  background: #f0efec;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 22px;
  margin-top: 10px;
  min-height: 160px;
}
.dash-inner-boxes h6 {
  color: #071523;
  font-family: "Futura Hv BT";
  text-transform: uppercase;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.dash-inner-boxes h4 {
  color: rgba(7, 21, 35, 0.7);
  font-family: "Futura Bk BT";
  font-size: 22px;
  font-style: normal;
  margin-top: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}
.dash-inner-boxes p {
  color: #979797;
  font-family: "Futura Md BT";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 0;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.dashbox-side {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 25px 25px;
  height: 100%;
}
.dashbox-side h2 {
  color: #3a7563;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 56px;
  letter-spacing: -0.4px;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.dash-graph img {
  width: 100%;
  height: 500px;
}
.dash-bottom {
  margin-top: 30px !important;
}
.table-cmn.table.table-sm {
  margin-bottom: 64px;
}
.side-menu a:hover {
  background: #b9b5ab87;
}
.side-menu a.bar_active {
  background: #b9b5ab;
  color: #000;
}

.heading-top-inner {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 25px 20px 25px 20px;
}
.heading-top-inner h2 {
  margin: 0;
  color: #000;

  font-size: 30px;
  font-weight: 700;

  line-height: 32px;
  letter-spacing: -0.6px;
}
.heading-top-inner p {
  margin: 0;
  color: #3a7563;

  font-size: 13px;
  font-style: normal;
  margin-top: 3px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.heading-top-inner h4 {
  color: #000;
  text-align: right;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.3px;
  margin: 0;
}
.heading-top-inner h2 {
  margin: 0;
}

.product-tab-cmn-btns .buttons {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #40413a;

  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 25px;
  margin-left: 15px;
}

.product-tab-cmn-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-right: 8px;
}
.product-tab-left a {
  color: #40413a;

  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}
.product-tab-left a svg {
  margin-right: 10px;
}
.product-cmn-tab {
  margin: 10px 0;
}
.product-tab-cmn-btns .active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}
.product-cont-boxes {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 15px 20px;
}
.product-cont-boxes h6 {
  color: #3a7563;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.product-cont-boxes h2 {
  color: #3a7563;

  font-size: 26px;
  margin-top: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}
.product-cont-boxes {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 20px 35px;
  margin-bottom: 20px;
}

.product-graph img {
  width: 100%;
}
.product-table-top {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 15px 0;
  margin-top: 20px;
}
.product-table-top h3 {
  color: rgba(32, 33, 36, 0.69);
  text-align: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}
.table-cmn .total-amount {
  color: #3eaf3f;
  font-weight: 600;
}
.table-cmn .balance {
  color: #000;
  font-weight: 600;
}
h2.property {
  font-weight: 400;
}
.product-overview-box {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 50px;
}

.product-overview-box h2 {
  color: #071523;
  font-family: "Futura Hv BT";
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 30px;
}
.product-overview-bottom h3 {
  color: #3a7563;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.3px;
}
.product-overview-bottom p {
  color: #3a7563;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin: 0;
}
.bottom-down button {
  color: #3a7563;
  text-align: right;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  background: none;
  border: none;
}
.bottom-top button {
  color: #3a7563;
  text-align: right;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  background: none;
  border: none;
}
.product-overview-box .edit-product {
  border: 1px solid #1ba97d;
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 14px 0px rgba(27, 169, 125, 0.4);
  display: block;
  width: 100%;
  color: #fff;
  text-align: center;

  font-size: 13px;
  margin-top: 8px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  border: none;
  padding: 10px 0;
}
.product-overview-bottom .bottom-top {
  margin-bottom: 15px;
}
.product-overview-bottom .bottom-down {
  margin-bottom: 15px;
}
.product-cmn-color {
  background: #d9d9d9;
}

.product-cmn-color hr {
  color: #e9edf7;
  margin: 1px !important;
  opacity: 1;
}
.table-spacing-between {
  width: 500px;
  max-width: 100%;
}

.transact-amount h3 {
  color: rgba(32, 33, 36, 0.69);

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.3px;
}
.transact-amount span {
  color: rgba(32, 33, 36, 0.69);

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-left: 6px;
}
.product-transactions .transact-amount {
  display: flex;
}
.product-transactions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 10px;
}
.product-transactions .transact-amount:nth-child(2) {
  margin-left: 30px;
}

.heading-top-inner h2 img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 67px;
  margin-right: 10px;
}
.customer-form {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}
.customer-form h2 {
  color: #071523;
  font-family: "Futura Hv BT";
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
}
.customer-form label {
  color: #000;

  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.customer-form input {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 10px 10px;
  color: #3a7563;
  font-size: 13px;
  border-radius: 0;
}
.customer-form input::placeholder {
  color: #3a7563;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
}
.customer-form input:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.profile-products-sec {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
}
.profile-products-sec h2 {
  color: #071523;
  font-family: "Futura Hv BT";
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
  margin-left: 50px;
}
.product-inner h3 {
  color: #3a7563;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}
.product-inner p {
  color: #3a7563;

  font-size: 15px;
  font-weight: 400;
  margin-top: 5px !important;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin: 0;
}

.product-inner-right button {
  color: #000;
  text-align: center;

  font-size: 12px;
  font-weight: 400;
  border: none;
  padding: 4px 50px;
  background: #d9d9d9;
}
.product-inner-right p {
  color: #3a7563;
  text-align: right;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-top: 10px;
  margin-bottom: 0;
}
.product-main-box {
  padding: 0 50px;
}
.product-inner-right {
  text-align: end;
}
.product-inner-right .closed-btn {
  background-color: #000;
  color: #fff;
  font-weight: 300;
}

.admin-box-top h3 {
  text-align: right;
  font-family: "Futura Hv BT";
  font-size: 13px;
  font-weight: 400;
  color: #071523;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.admin-box-inner p {
  color: #6a6d60;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-align: justify;
}
.admin-box-main {
  padding: 0 50px;
}
.admin-box-top h3 span {
  margin: 0 10px;
}

.admin-box-inner textarea {
  border: 0.25px solid #d9d9d9;
  border-radius: 0;
  color: #6a6d60;
  font-size: 13px;
  font-weight: 400;
}
.admin-box-inner textarea:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}
.admin-box-inner textarea::placeholder {
  color: #6a6d60;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
}
.admin-box-main hr {
  margin: 20px 0;
}

.product-actions {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
}
.product-actions h2 {
  color: #3a7563;

  font-size: 13px;
  padding-left: 70px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.product-action-inner h3 {
  color: #000;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}
.product-action-inner p {
  color: #000;
  text-align: right;

  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.24px;
}
.product-actions .add-btn {
  margin-right: 50px;
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  font-size: 13px;
  font-weight: 400;
  padding: 10px 30px;
}

.cmn-border {
  color: #dadfdd;

  margin: 1px !important;
  opacity: 1;
}
.cmn-background {
  background: #d9d9d9;
}

.table-cmn .total-amount-withdraw {
  color: #e14942;
  font-weight: 600;
}

.form-save-btn button {
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  padding: 11px 58px;
  font-size: 13px;
  font-weight: 400;
}

.customer-form-new h2 {
  color: #071523;

  font-family: "Futura Hv BT";
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
  margin-top: 35px;
  padding: 0 50px;
}
.customer-form-inner {
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}
.customer-form-new {
  border: 0.25px solid #6a6d6038;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
}
.customer-form-new label {
  color: #071523;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.customer-form-new input {
  border: 0.25px solid #6a6d6040;
  background: #fff;

  padding: 10px 10px;
  color: #000;
  font-size: 13px;
  border-radius: 0;
}
.customer-form-new input::placeholder {
  color: #000;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
}
.customer-form-new input:focus {
  box-shadow: none;
  border: 0.25px solid #6a6d6040;
}
.customer-form-new select {
  border: 1px solid #c1dece;
  background: #fff;
  padding: 10px 10px;
  color: #3a7563;
  font-size: 13px;
  border-radius: 0;
}
.customer-form-new select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}
.form-save-btn-new button {
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  padding: 8px 70px;
  font-size: 13px;
  font-weight: 400;
}

.product-overview-box .edit-product-new {
  background: #fff;
  display: block;
  width: 100%;
  color: #3a7563;
  text-align: center;

  font-size: 13px;
  margin-top: 8px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  border: none;
  padding: 10px 0;
  border: 0.25px solid #d9d9d9;
}

.member-info-inner h2 {
  margin-bottom: 10px;
}
.member-inner p {
  color: #6a6d5f;
  margin-bottom: 4px;

  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 400;
}
.address-main p {
  color: #6a6d5f;

  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.member-inner p:first-child span {
  color: #1462d7;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: -0.3px;
}
.product-overview-box select {
  border: 0.25px solid #d9d9d9;
  border-radius: 0;
  color: #071523;
  text-align: center;

  font-size: 13px;
  font-weight: 400;
  padding: 8px;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.product-overview-box select:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}
.cmn-btn button {
  border: 1px solid #071523;
  background: #071523;
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 10px 65px;
}
.product-action-inner h3 a {
  color: #1462d7;
}

.access-select-box {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  margin-bottom: 8px;
  align-items: center;
}
.access-select-box h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.access-select-box .inner h3 {
  margin-left: 30px;
  width: 23px;
}
.access-select-box .form-check-inline {
  margin-right: 0;
  margin-left: 30px;
}
.access-select-box p {
  margin-bottom: 0;
  color: #000;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.access-select-box .form-check-input {
  border-radius: 5px !important;
  border: 1px solid #979797;
  background: #fff;
  cursor: pointer;
}
.access-select-box .form-check-input:checked {
  border-radius: 5px;
  border: 1px solid #979797;
  background: #979797;
}
.input-image-show {
  border: 0.25px dashed #979797;
  background: #fff;
  width: 100%;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input-image-show p {
  color: #6a6d60;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}
.input-image-show input {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
}

.blog-save-btn {
  text-align: center;
  margin-top: 44px;
}
.blog-save-btn button {
  padding: 12px 65px;
}

.product-overview-box.blog-bottom {
  padding-bottom: 50px;
}
.check-box-inner {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.check-box-inner form {
  margin-left: 10px;
}
.check-box-inner form input {
  border-radius: 5px !important;
  border: 1px solid #97979785;
  background: #fff;
  width: 20px;
  height: 20px;
}
.check-box-inner form input:checked {
  border-radius: 5px;
  border: 1px solid #979797;
  background: #979797;
}
.create-mail-checks {
  display: flex;
  align-items: center;
}

.customer-form-inner textarea {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 10px 10px;
  color: #3a7563;
  font-size: 13px;
  border-radius: 0;
}
.customer-form-inner textarea:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}
.dash-graph.right {
  margin-right: 12px;
}

.user-img img {
  width: 40px;
  border-radius: 70px;
  height: 40px;
  object-fit: cover;
}
.select-box select {
  width: fit-content;
  border: 1px solid #dee2e6;
  border-radius: 0;
  color: #fff;
  background: #979797;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.select-box select:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.order-btn a {
  border: 1px solid #1ba97d;
  background: #fff;
  color: #1ba97d !important;

  width: 85px;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.accept-reject-btn button {
  background: linear-gradient(180deg, #1ba97d, #118d67);
  border: 1px solid #1ba97d;
  box-shadow: 0 10px 14px 0 #1ba97d66;
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 90px;
  border-radius: 0;
  margin: 0 10px;
}
.accept-reject-btn {
  display: flex;
  justify-content: center;
}

.admin-box-select select {
  border-radius: 0;
  color: #3a7563;

  font-weight: 600;
  font-size: 14px;
  height: 40px;
  letter-spacing: -0.3px;
  line-height: 24px;
  max-width: 270px;
  padding-left: 20px;
}
.customer-form-inner textarea::placeholder {
  color: #3a7563;
  font-size: 13px;
  font-weight: 600;
  font-size: 14px;
}
.customer-form-new input input::placeholder {
  color: #3a7563;
  font-size: 13px;
  font-weight: 600;
  font-size: 14px;
}
.customer-form-inner .admin-box-select select {
  appearance: auto;
}

.admin-box-select select:focus {
  box-shadow: none;
}

.account-btn {
  background-color: #071523 !important;
  border: 1px solid #000 !important;
  color: #fff;
  text-align: center;

  margin: 0 2px !important;
  font-size: 13px !important;
  font-style: normal;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: normal;
  text-decoration: none;
  padding: 8px 20px !important;
  border-radius: 0 !important;
  margin: 10px 0;
}

.account-btn-delete {
  background: #979797 !important;
  border: 1px solid #979797 !important;
  color: #fff;
  text-align: center;

  margin: 0 2px !important;
  font-size: 13px !important;
  font-style: normal;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: normal;
  text-decoration: none;
  padding: 8px 33px !important;
  border-radius: 0 !important;
  margin: 10px 0;
}
.account-btn:hover {
  background-color: #071523 !important;
  border: 1px solid #000 !important;
  color: #fff;
}
.add-btn {
  border: 1px solid #1ba97d;
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 14px 0px rgba(27, 169, 125, 0.4);
  color: #fff;
  text-align: center;

  font-size: 13px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 70px;
}

.customer-form-inner-new {
  border: 0.25px solid #d9d9d9;
  padding: 20px 20px;
}

.support-chat-box-main {
  padding: 22px;
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}

.user-top-hdng {
  display: flex;
  align-items: center;
}
.user-top-hdng img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 10px;
}
.user-top-hdng h3 {
  font-size: 22px;
  text-transform: capitalize;
  margin-right: 11px;
}

.user-top-hdng h4 {
  font-size: 11px;
  margin: 0;
}
.user-msg-box {
  background: #eff8f3;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 25px;
  color: #000;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 40px;
  margin-top: 10px;
  padding: 15px 34px;
}
.support-inner h2 {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}
.support-msg-box {
  padding: 20px 10px;
  height: 55vh;
  overflow-y: scroll;
}
.user-box {
  margin-bottom: 15px;
}

.send-box {
  bottom: 0;
  position: absolute;
  width: 95%;
}
.send-feild {
  background: #eff8f3 !important;
  border: none !important;
  padding: 15px !important;
}

.send-box input:focus {
  box-shadow: none;
}

.dash-graph {
  border: 0.25px solid #6a6d6036;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 25px;
  height: 100%;
}
.inner-user {
    padding: 25px 40px;
    background-color: #b9b5ab;
    margin-bottom: 20px;
}
.toggle-icon {
  position: fixed;
  left: 245px;
  padding: 5px 6px;
  background-color: #071523;
  width: 40px;
  height: 40px;
  top: 70px;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}
.toggle-icon img {
  width: 20px;
  height: 18px;
}
.left-side.hide aside .side-menu a p {
  display: none;
}
.left-side.hide aside {
  width: 100px;
}
.right-side-cmn.hide {
  width: calc(100% - 100px);
}

.left-side.hide .toggle-icon {
  transform: rotateY(180deg);
  left: 78px;
}

.left-side.hide .side-menu a {
  justify-content: center;
}
.right-side-cmn.hide .next-btn-fix {
  width: calc(100% - 100px);
}

.user-profile-main figure {
  margin: 0;
}
.user-profile-main img {
  width: 100px;
  border-radius: 79px;
  object-fit: cover;
  height: 100px;
}
.user-profile-main h2 {
  padding-bottom: 6px;
  margin: 0;
  font-family: "Futura Hv BT";
  color: #071523;
  font-size: 20px;
  font-weight: 400;
}
.user-profile-main p {
  margin: 0;
  font-size: 14px;
  padding: 2px 0;
  font-weight: 400;
  color: #40413a;
}

.user-profile-main figcaption {
  margin-left: 20px;
}

.logo-side .small {
  display: none;
}
.left-side.hide .logo-side .small {
  display: block;
}
.left-side.hide .logo-side .big {
  display: none;
}

.user-profile-main {
  border: 0.25px solid #6a6d6024;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 15px;
}

.send-box button img {
  transform: rotatey(180deg);
}
.logo-side .small {
  width: 40px;
}

.user-profile-main p span {
  font-weight: 400;
}

.user-profile-all {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}

.user-profile-all h3 {
  text-align: center;
  color: #6a6d6f;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}
.user-profile-all span {
  font-weight: 400;
}
.dash-graph .gender-chart {
  height: 400px !important;
  margin: auto;
  max-width: 400px !important;
}

.product-overview-box p {
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 15px 0px rgba(22, 155, 114, 0.4);
  padding: 10px 10px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 4px;

  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
}

.admin-box-select label {
  color: #3a7563;
  font-size: 13px;
  font-weight: 400;
}

.dash-graph h6 {
  color: #071523;
  font-family: "Futura Hv BT";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.customer-form.profile h2 {
  font-size: 18px;
  font-weight: 600;
}

.property-name-show {
  width: fit-content;
  display: flex;
  align-items: center;

  padding: 10px 12px;
  border-radius: 10px;
  background: linear-gradient(180deg, #6c9459 0%, #39562e 100%);
  box-shadow: 0px 5px 15px 0px rgba(27, 169, 125, 0.2);
  margin-top: 15px;
}
.property-name-show img {
  width: 65px;
  border-radius: 10px;
  height: 64px;
  margin-right: 10px;
}
.property-name-show h2 {
  color: #fff !important;

  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}
.property-name-show h3 {
  color: #fff;

  font-size: 14px;
  font-style: normal;
  opacity: 0.7;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}

.property-main h2 {
  color: #071523;
  text-transform: uppercase;

  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.3px;
}
.property-main {
  margin-top: 35px;
  margin-left: 12px;
}

.dashboard-items.profile .dash-inner-boxes {
  margin-top: 10px;
}

.property-name-show h3 {
  margin: 0;
  padding: 0;
  border: 0;
}

.assign {
  border: 1px solid #c1dece;
  padding: 17px 10px !important;
  font-size: 13px !important;
  text-transform: uppercase;
}
.logo img {
  width: 220px;
}

.dash-inner-boxes.vendor {
  margin: 0;
}

.status-box h2 {
  margin: 0;
  color: #fff;
}
.status-box {
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 15px 0px rgba(22, 155, 114, 0.4);
  width: 200px;
  border-radius: 8px;
  padding: 9px 18px;
  margin-top: 20px;
  margin-right: 10px;
}
.status-box p {
  color: #fff;
}

.add-btn-category {
  color: #fff;
  border: 1px solid #1ba97d;
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 14px 0px rgba(27, 169, 125, 0.4);
  width: 97px;
  font-size: 14px;
  height: 36px;
  border-radius: 0;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.change-pass {
  height: 100%;
  margin-top: 30px;
}

.property-name-show.view h2 {
  margin: 0;
}

a.menu-btn.bar_active path {
  stroke: #000 !important;
}

.property-name-show.customer {
  background: none;
  box-shadow: none;
}
.property-name-show.customer h2 {
  color: #3a7563 !important;
  font-weight: 600;
}
.property-name-show.customer h3 {
  color: #3a7563;
  font-weight: 600;
}
.property-name-show.customer img {
  width: 130px;
  height: 115px;
}

.dash-inner-boxes.small {
  height: 100px !important;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customer-form-new.card h2 {
  color: #071523;

  font-size: 16px;
  text-transform: uppercase;

  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.login-box-inner-wrap input:focus {
  border: 0.25px solid #dee2e6;
  background: #fff;
}

.user-profile-main h3 {
  color: #3a7563;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.admin-box-select select:focus {
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.property-name-show.view {
  background: none;
  box-shadow: none;
}
.property-name-show.view h2 {
  color: #3a7563 !important;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}

.menu-btn.bar_active .dash-only path {
  stroke: none !important;
  fill: #000 !important;
}

.table-cmn.manager img {
  width: 60px;
  height: 60px;
}
.table-cmn td {
  display: table-cell;
  vertical-align: middle;
}

.side-menu .bar_active .fill-active path {
  stroke: none !important;
  fill: #000 !important;
}

.side-menu .bar_active svg path {
  stroke: #000 !important;
  fill: none !important;
}

.google-analytics {
  background: #fff;
  border: 0.25px solid #6a6d6036;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  height: 274px;
  justify-content: center;
}
.google-analytics p {
  color: #000;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
}

/* Profile view css  start  */

.user-profile-main hr {
  margin: 30px 0px;
}

.profile-hours {
  display: flex;
  justify-content: space-between;
}

.dlt-ac-btn a {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 400;
  text-decoration: none;
  padding: 11px 33px;
}

.user-profile-main figcaption input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.user-profile-main figcaption .form-check-input {
  height: 22px;
}

.user-profile-main figcaption .form-switch .form-check-input {
  width: 3em;
  border: var(--bs-border-width) solid #b9b5ab;
  cursor: pointer;
}

.user-profile-main figcaption .form-check-input:checked {
  background-color: #b9b5ab;
}

.user-profile-main figcaption .form-switch .form-check-input:focus {
  filter: grayscale(1);
}
/* Profile view css End  */

.cmn-btn a svg {
  height: 17px;
  width: 18px;
  margin-right: 12px;
}

.off-treatements ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.off-treatements ul li {
  margin-right: 11px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #d9d9d9;
  color: #000;
  margin-bottom: 10px;
  width: fit-content;
  padding: 8px 10px;
}

.Salon-links ul {
  padding: 0;
  list-style: none;
}
.Salon-links ul li {
  display: flex;
  border-bottom: 1px solid #b9b5ab6e;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}
.Salon-links ul li h6 {
  color: #071523;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.Salon-links ul li p {
  font-size: 13px;
}

.review-detail {
  border: 0.25px solid #6a6d6054;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 35px 35px;
}

.review-detail h2 {
  color: #071523;
  font-family: "Futura Hv BT";
  font-size: 30px;
  font-weight: 500;
}
.review-detail .text-center p {
  color: #071523;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  font-family: "Futura Lt BT";
  margin: 0;
}

.review-detail .style-module_starRatingWrap__q-lJC {
  margin-top: 13px;
}

.detail-inner {
  margin: 35px 0;
  border-bottom: 1px solid #d5d1d1;
}

.detail-inner h2 {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  font-family: "Futura Md BT";
}

.detail-inner ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.detail-inner ul li {
  background: #b9b5ab;
  color: #fff;
  font-size: 16px;
  margin-right: 20px;
  width: 200px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  margin-bottom: 20px;
}

.detail-text p {
  color: #000;
  padding-top: 25px;
  font-size: 23px;
  font-weight: 400;
  border-bottom: 1px solid #d5d1d1;
  padding-bottom: 10px;
}

.review-data h3 {
  color: #000;
  font-size: 20px;
  font-weight: 400;
}
.review-data p {
  color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  font-weight: 400;
}

.details-inner h2 {
  color: #000;
  font-size: 20px;
  font-weight: 400;
}

.details-inner {
  margin: 40px 0;
}
.review-details-btns {
  margin: 25px 0;
  display: flex;
  justify-content: center;
}
.review-details-btns button {
  margin: 0 10px !important;
}
.details-inner p {
  margin-top: 18px;
  font-size: 13px;
  font-family: "Futura Lt BT";
}

.cmn-btn a img {
  margin-right: 10px;
}

.admin-blog-main {
  border: 1px solid #979797;
  background: #fff;
  padding: 20px 20px;
  margin-bottom: 10px;
}
.admin-blog-main img {
  border-radius: 0;
  width: 100%;
}

.admin-blog-bottom p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-family: "Futura Lt BT";
  margin: 0;
  padding: 10px 0;
}
.admin-blog-bottom h2 {
  color: #071523;
  font-size: 22px;
  font-weight: 400;
  font-family: "Futura Md BT";
}
.blog-btns button {
  background: #b9b5ab;
  width: calc(95% / 2);
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
}
.blog-btns {
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.side-menu .bar_active .fill-active rect {
  stroke: #000;
}

.tble-time-txt {
  display: block;
}

.select-box select {
  background-image: url("../Images/downarrow.svg");
  background-repeat: no-repeat;
  background-position: center right 14px;
  background-size: 10px;
}

.product-action-inner h3 {
  color: rgba(7, 21, 35, 0.7);
  margin: 0;
}
.product-action-inner p {
  font-size: 12px;
}
.product-action-inner {
  border-bottom: 1px solid #b9b5ab;
  padding-bottom: 2px;
  padding-top: 14px;
}

.text-editor .tox.tox-tinymce {
  border-radius: 0;
  border: 0.25px solid #6a6d6038;
}

.text-heading h3 {
  color: #6a6d60;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}
.text-heading {
  border-bottom: 0 !important;
  padding: 25px 48px;
  border: 0.25px solid #6a6d6038;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
}
.text-editor .tox:not(.tox-tinymce-inline) .tox-editor-header {
  box-shadow: none;
}

.pagination_new .pagination li a {
  color: #000;
  padding: 6px 10px;
  text-decoration: none;
}
.pagination_new .pagination {
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 10px;
  padding: 10px 10px;
}
span.notify-badge {
  background: #c4c2c2;
  border-radius: 6px;
  padding: 4px 7px;
}
.pagination_new {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.pagination_new .active a {
  background: #c4c2c2;
  border-radius: 6px;
}

.login-cmn-box .submit {
  justify-content: center;
  display: flex;
  background: #5f6368;
  border: none;
  border-radius: 0;
}

.formik-errors {
  color: red;
  font-size: 13px;
}
.dash-graph h4 {
    font-size: 30px;
    font-weight: 600;
}
.progress-line {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
p.no-data-found {
    width: 100%;
    text-align: center;
}
.fit_img {
  object-fit: contain;
  width: 140px;
  height: 120px;
}
.login-cmn-box .submit:hover {
  background: #5f6368;
}

.text-editor .rdw-dropdown-selectedtext span {
  color: #000;
  text-decoration: none;
}
.rdw-dropdown-selectedtext {
  text-decoration: none;
}

.text-editor .rdw-editor-wrapper {
  min-height: 470px;
  padding: 10px;
  border: 0.25px solid #6a6d6038;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
}

.text-editor .rdw-editor-main {
  padding: 0 42px;
}

.dashboard-items.profile {
  margin: 0;
}

.dashboard-items.profile .dash-inner-boxes {
  min-height: 130px !important;
}

.customer-form-new.border-none {
  border: none !important;
  background: #fff;
  box-shadow: none !important;
}

.category-select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.main-category .category-select {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  min-width: 274px;
}
.category-select input {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: 2px solid #b9b5ab;
  border-radius: 0 !important;
}

.category-select label {
  color: #071523;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}

.category-select .form-check-input:checked {
  background-color: #b9b5ab;
  border-color: #b9b5ab;
}

.category-select .form-check-input:focus {
  border-color: #b9b5ab;
  box-shadow: 0 0 0 0.25rem #fff0;
  outline: 0;
}

.admin-actions-btn .dlt-ac-btn a {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 400;
  text-decoration: none;
  padding: 11px 43px;
  width: 100% !important;
  display: flex;
  justify-content: center;
}


.customer-form-new textarea {
  border: 0.25px solid #6a6d6040;
  background: #fff;
  padding: 10px 10px;
color: #000;
  font-size: 13px;
  border-radius: 0;
  resize: none;
}

.delete-popup .modal-header {
  border: none;
}
.delete-popup .modal-footer {
  border: none;
  padding-bottom: 30px;
}
p.width-set-dots {
  display: inline-block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.delete-popup button.btn.btn-secondary {
  background: #000;
 
  border: none;
  padding: 5px 36px;
}
.delete-popup button.btn.btn-primary {
  background: #000;

  border: none;
  padding: 5px 36px;
}
.delete-popup .modal-body {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  padding-top: 30px;
}
.delete-popup .modal-footer {
  justify-content: center;
}

.property-main a {
    text-decoration: none;
}

.Salon-links input {
    font-size: 13px;
    border-radius: 0;
}

.opening-days-select select.form-control {
    border: 1px solid #b9b5ab;
    background: #fff;
    border-radius: 0;
    height: 50px;
    appearance: auto;
     --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #b9b5ab;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 60px;
}

.Opening-add-btns button {
    width: 50px;
    background: none;
    height: 50px;
      border: 1px solid #b9b5ab;
    margin-right: 10px;
}
.Opening-add-btns button:first-child img {
    width: 30px;
    height: 30px;
}
.Opening-add-btns button:last-child img {
    width: 22px;
    height: 22px;
}


.buisness_form_fields {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.buisness_form_fields p {
  color: #5f6368;

  font-size: 18px;
  font-style: normal;
  position: unset;
  font-weight: 500;
  letter-spacing: -.4px;
  line-height: 42px;
  margin: 0;
  max-width: 100%;
  width: 150px;
}
.buisness_form_fields .form-select {
  border: none;
  border-bottom: 1px solid #b2b2b2;
  border-radius: 0;
  color: #565b738c;
  font-size: 16px;
  padding: 0 0 2px 1px;
  width: 38%;
}
.edit_btns {
  display: flex;
  width: 100px;
}
.edit_btns button {
  background: none;
  border: none;
  padding-right: 5px;
    padding-left: 0;
}
.buisness_form_fields input {
  border: none;
  border-bottom: 1px solid #00000045;
  border-radius: 0;
  color: #565b73;
  font-size: 16px;
  max-width: 100%;
  padding-left: 0;
  width: 78%;
}
.edit_btns {
    display: flex;
    width: 100px;
    margin-bottom: 5px;
}
.edit_btns img {
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  cursor: pointer;
}
.edit_btns button:first-child img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  padding: 6px;
}
.association-sec img {
    width: 100px;
}


.serach-admin {
    display: flex;
    align-items: center;
}
.serach-admin span {
    width: 45px;
    border-radius: 0;
    height: 42px;
    background: #b9b5ab;
    border: 1px solid #b9b5ab;
}
.serach-admin span img {
    width: 20px;
    height: 20px;
}
.srach-admin select.form-control.form-select {
    color: #000;
    border: 1px solid #d9dad7;
    margin-top: 8px;
}
.sign-upload-btn button {
    border: 1px solid #071523;
    background: #071523;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
 
    padding: 11px 15px;
}
.sign-upload-btn {
    position: relative;
    width: 175px;
    margin-top: 10px;
}
.sign-upload-btn input {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.img-upld-box {
  text-align: center;
  position: relative;
  border: 2px dashed #b9b5ab;
  padding: 30px;
  height: 160px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-upld-box p {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}
.img-upld-box input {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
.img-upld-box .view-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 0;
  height: 100%;
  object-fit: cover;
}
img.upload-img-icon {
  width: 35px;
  height: 40px;
  border-radius: 0;
  object-fit: contain;
}


.dash-graph .event-link {
    font-size: 15px;
    text-decoration: none;
    color: #808080;
}


.dash-graph p {
    font-size: 15px;
    text-decoration: none;
    color: #808080;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px!important;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.image-view-new {
  color: rgba(var(--bs-link-color-rgb), 1);
  text-decoration: underline;
  cursor: pointer;
}

.loader-main {
  background: #00000030;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 11;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-filed-set div {
  display: flex;
  gap: 25px;
}
.new-filed-set div {
  width: 390px !important;
}
.new-filed-set p {
  width: 140px;
}

.dlt-ac-btn button {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 43px;
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.cmn-doc {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 43px;
  width: fit-content;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}
.cmn-doc img {
  width: 20px;
  margin-left: 10px;
}
.documents-upload .form-group {
  margin-bottom: 10px;
}
.upload-input {
  margin-bottom: 10px;
}

.bg-input-shadow-c {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
  color: #000;
  display: flex;
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  justify-content: space-between;
  letter-spacing: -.02em;
  line-height: 24px;
  min-height: 44px;
  margin-top: 20px;
  padding: 10px;
}
.group-icon-text {
  display: flex;
  width: 100%;
}

.bg-input-shadow-c p {
  margin: 0;
  font-family: "Futura Md BT" !important;
  font-size: 14px;
  font-weight: 500;
}
.group-icon-text img {
  padding-right: 20px;
  width: 40px;
  height: 25px;
  object-fit: scale-down;
}

.padf {
font-weight: 600;
}

.certificate {
  color: #071523;
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: 24px!important;
  text-transform: capitalize!important;
  font-family: "Futura Md BT" !important;
  letter-spacing: -0.3px;
}

.overflow_prop-170 {
    border: 2px dashed #b9b5ab;
    height: 160px;
    overflow-y: scroll;
    padding: 0 10px;
    margin-top: 7px;
}
.overflow_prop-170::-webkit-scrollbar {
 display: none;
}




.bg-input-shadow-c svg {
    margin-right: 10px;
}
button.ms-2.black-btn {
  width: 180px;
  padding: 11px 10px;
}